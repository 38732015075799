html, body {
    width: 100%;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px;
}

.bx--label {
    font-size: 18px;
}

.bx--form__helper-text {
    font-size: 14px;
}

.bx--form__helper-text {
    max-width: 100%;
}

.bx--header__menu-bar {
    justify-content: center !important;
}

.bx--inline-notification {
    max-width: 100%;
}

@media (min-width: 42rem) {
    .bx--inline-notification {
        max-width: 100%;
    }
}

@media (max-width: 600px) {
    .bx--table-toolbar {
        padding-left: 16px;
        height: 4rem;
    }

    .bx--toolbar-content {
        justify-content: left;
    }

    body {
        overflow-x: hidden;
    }
}
