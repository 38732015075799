.centreAlign {
  text-align: center !important;
}

.styleLessButton {
  border: none;
  margin: 0;
  text-decoration: none;
  background: none;
  outline: 0;
  cursor: pointer;
}
