.imageContainer {
  width: 250px;
  object-fit: contain;
}

.videoContainer {
  width: 500px;
  object-fit: contain;
}

.buttonCentre {
  text-align: center;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag {
  margin-left: 0;
  margin-top: 10px;
  border-radius: 0;
}
