.autoCompleteBox {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 400px;
  height: 40px;
  padding:  12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  position: absolute;
  left: 50%;
  margin-left: -120px;
  margin-top: 10px;
}

.mapContainer {
  height: 400px;
  width: 100%;
}
