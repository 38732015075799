.overRide {
  display: block !important;
  background-color: #161616 !important;
  width: 100%;
  text-align: center;
  padding-left: 0;
  justify-content: center;
}

.overRide::before {
  background-color: #161616 !important;
}

.header {
  height: 40px;
  flex-flow: wrap;
  background-color: #161616;
  width: 100%;
  text-align: center;
  display: inline !important;
  padding-top: 5px;
}

.navBar {
  background-color: #161616;
  width: 100%;
  text-align: center;
}

.headerText {
  margin-left: 0;
  padding-left: 0;
  display: inline !important;
  width: 100% !important;
}
