.marginLeft {
  margin-left: 20px;
}

.deleteButtonPadding {
  margin-top: 24px;
}

.addMoreWidthMax {
  max-width: 170px;
}

.dropDownMaxWidth {
  max-width: 800px;
}

.bottomPadding {
  padding-bottom: 10px;
}

.smallBottom {
  margin-bottom: -20px;
}
