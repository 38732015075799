@import '~@carbon/themes/scss/themes';

.container {
  margin-top: 85px;
}

.spacer {
  padding-bottom: 0;
}

.spacer-small {
  padding-bottom: $spacing-01;
}

.spacer-medium {
  padding-bottom: $spacing-06;
}

.spacer-large {
  padding-bottom: $spacing-09;
}
