.centreAlign {
  text-align: center !important;
}

.largeButton {
  padding: 17px;
}

.iconGap {
  margin-left: 10px;
  fill: white;
}

.logo {
  width: 200px;
}

.imageContainer {
  width: 100%;
}

.largeButton {
  width: 250px
}

@media screen and (max-width: 600px) {
  .imageContainer {
    width: 100%;
    text-align: center;
  }

  .largeButton {
    width: 100%
  }
}
