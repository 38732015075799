.termsAndConditions {
  width: 50%;
}

.bottomPadding {
  padding-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .termsAndConditions {
    width: 100%;
  }
}
